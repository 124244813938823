





























































import { BootstrapButton, BootstrapCheck, BootstrapIcon, BootstrapInput } from '@movecloser/ui-core'
import { Component, Prop } from 'vue-property-decorator'
import { TranslateResult } from 'vue-i18n'

import { Color, InjectionCallback } from '../../../../../contracts'
import { AnswerFormData, IQuestionsRepository, QuestionsRepositoryType } from '../../../../../repositories/questions'

import { MachineEvent } from '../../QuestionForm.contracts'
import { validateNickname, validateContent, validateAge } from '../../QuestionForm.helpers'

import { AbstractState } from './_abstract'

/**
 * Displays the question form along with all current errors.
 *
 * @author Łukasz Bęben <lukasz.beben@movecloser.pl>
 */
@Component<DataEntry>({
  name: 'DataEntry',
  components: {
    BootstrapButton,
    BootstrapCheck,
    BootstrapIcon,
    BootstrapInput
  }
})
export class DataEntry extends AbstractState {
  /**
   * Determines question id.
   */
  @Prop({ type: Number, required: false, default: '' })
  public readonly questionId?: number

  public readonly Color = Color

  /**
   * Textarea placeholder.
   */
  public readonly contentPlaceholder: string =
    this.$t('modules.components.molecules.QuestionForm.partials.states.DataEntry.fields.content') as string

  /**
   * Object to store form errors
   */
  public errors: null | Record<string, string[]> = null

  /**
   * Resolves the dependency bound to the passed-in symbol.
   *
   * @param injectionType - The symbol that the resolved dependency is associated with.
   */
   public readonly resolveInjection: InjectionCallback = (injectionType) => {
     if (typeof this.$container === 'undefined') {
       throw new Error('App.resolveInjection(): [this.$container] is [undefined]!')
     }

     return this.$container.get(injectionType)
   }

   /**
   * An instance of the `QuestionsRepository`.
   */
   private get questionsRepository (): IQuestionsRepository {
     return this.resolveInjection<IQuestionsRepository>(QuestionsRepositoryType)
   }

  /**
   * Data entered by the User.
   */
  public formData: AnswerFormData = {
    content: '',
    nickname: '',
    age: undefined,
  }

  /**
   * Determines whether the component should be put in the "loading" state.
   */
  public isLoading: boolean = false

  /**
   * Determines whether there are any form errors to display.
   */
  public get hasErrors (): boolean {
    const errors = this.context.errors

    return typeof errors !== 'undefined' &&
      Array.isArray(errors) &&
      errors.length > 0
  }

  /**
   * Resolves the errors for the specified field.
   *
   * @param key - Key under which the errors are stored.
   */
  public getErrors (key: string): TranslateResult[] | undefined {
    if (!this.errors || !this.errors[key]) {
      return
    }

    return this.errors[key].map<TranslateResult>(k => this.$t(k))
  }

  /**
   * Handles the `@submit` event on the main `<form>` element.
   */
  public onSubmit (): void {
    this.errors = null

    if (!this.validateFormData(this.formData)) {
      return
    }

    this.isLoading = true

    this.submitForm()
      .then(() => { this.send(MachineEvent.SubmissionAccepted) })
      .catch(() => { this.send(MachineEvent.SubmissionRejected) })
      .finally(() => { this.isLoading = false })
  }

  private async submitForm (): Promise<void> {
    return this.questionsRepository.addAnswer(this.$props.questionId, { ...this.formData, age: Number(this.formData.age) })
  }

  /**
   * Adds new errors to the errors array.
   *
   * @param key - Object key for the given errors.
   * @param errors - Array of errors to add.
   */
  private addErrors (key: string, errors: string[]): void {
    if (!this.errors) {
      this.errors = { [key]: errors }
      return
    }

    this.errors = {
      ...this.errors,
      [key]: errors
    }
  }

  /**
   * Validate the given form data.
   *
   * @param formData - Question form data entered by the User.
   *
   * @returns - Boolean value determining whether the provided data is valid.
   */
  private validateFormData (formData: AnswerFormData): boolean {
    let isFormValid = true

    if (!this.validateContent(formData.content)) {
      isFormValid = false
    }

    if (!this.validateNickname(formData.nickname)) {
      isFormValid = false
    }

    if (!this.validateAge(formData.age ?? 0)) {
      isFormValid = false
    }

    return isFormValid
  }

  /**
   * Checks if the provided string is a valid nickname.
   *
   * @param name - The string to check.
   */
  private validateContent (content: string): boolean {
    const { isValid, errors } = validateContent(content,
      'modules.components.molecules.QuestionForm.partials.states.DataEntry.errors.content')

    if (errors && errors.length > 0) {
      this.addErrors('content', errors)
    }

    return isValid
  }

  /**
   * Checks if the provided string is a valid nickname.
   *
   * @param name - The string to check.
   */
  private validateNickname (nickname: string): boolean {
    const { isValid, errors } = validateNickname(nickname,
      'modules.components.molecules.QuestionForm.partials.states.DataEntry.errors.nickname')

    if (errors && errors.length > 0) {
      this.addErrors('nickname', errors)
    }

    return isValid
  }

  /**
   * Checks if the provided number is a valid age.
   *
   * @param age - The number to check.
   */
  private validateAge (age: number): boolean {
    const { isValid, errors } = validateAge(age,
      'modules.components.molecules.QuestionForm.partials.states.DataEntry.errors.age')

    if (errors && errors.length > 0) {
      this.addErrors('age', errors)
    }

    return isValid
  }
}

export default DataEntry




















import { BootstrapButton, BootstrapIcon } from '@movecloser/ui-core'
import { Component } from 'vue-property-decorator'

import { MachineEvent } from '../../QuestionForm.contracts'

import { AbstractState } from './_abstract'

/**
 * Displays the information after the unsuccessful form submission.
 *
 * @author Łukasz Bęben <lukasz.beben@movecloser.pl>
 */
@Component<Error>({
  name: 'Error',
  components: {
    BootstrapButton,
    BootstrapIcon,
    UiHeading: () => import(
      /* webpackChunkName: "atoms/UiHeading" */
      '../../../../atoms/UiHeading/UiHeading.vue'
    )
  }
})
export class Error extends AbstractState {
  /**
   * Handles the `@click` event on the "try again" button.
   */
  public onTryAgainBtnClick (): void {
    this.send(MachineEvent.TryAgain)
  }
}

export default Error

// Copyright © 2021 Move Closer

import { Component, Mixins, Prop } from 'vue-property-decorator'

import { Colorable } from '../../../../../extensions'

import { MachineContext, MachineEvent, MachineEventData } from '../../QuestionForm.contracts'

/**
 * Abstract class that every state component **HAS TO** extend.
 *
 * @emits send - When the component sends the event to the finite state machine.
 *
 * @author Łukasz Bęben <lukasz.beben@movecloser.pl>
 */
@Component({ name: 'AbstractState' })
export class AbstractState extends Mixins<Colorable>(Colorable) {
  /**
   * Current state of the machine's context.
   */
  @Prop({ type: Object, required: true })
  public readonly context!: MachineContext

  /**
   * Sends the event to the finite state machine.
   *
   * @param event - The type of the event being fired.
   * @param [payload] - Additional event's payload.
   */
  public send (event: MachineEvent, payload?: MachineEventData): void {
    this.$emit('send', event, payload)
  }
}

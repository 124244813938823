// Copyright © 2022 Move Closer

/**
 * Payload for validation function
 *
 * @author Łukasz Bęben <lukasz.beben@movecloser.com>
 */
export interface ValidationPayload {
  isValid: boolean
  errors?: string[]
}

/**
 * Payload for checkbox validation function
 *
 * @author Łukasz Bęben <lukasz.beben@movecloser.com>
 */
export interface CheckboxListValidationPayload extends Omit<ValidationPayload, 'errors'> {
  errors?: Record<string, string[]>
}

/**
 * Method to validate name
 *
 * @param name - string to check if its valid name
 * @param translationKey: translation key for error messages
 *
 * @returns ValidationPayload
 *
 * @author Łukasz Bęben <lukasz.beben@movecloser.com>
 */
export const validateContent = (content: string, translationKey: string): ValidationPayload => {
  let isValid = true
  const errors: string[] = []

  if (content.length < 3) {
    isValid = false
    errors.push(translationKey + '.tooShort')
  }

  if (content.length > 500) {
    isValid = false
    errors.push(translationKey + '.tooLong')
  }

  return { isValid, errors }
}

export const validateNickname = (name: string, translationKey: string): ValidationPayload => {
  let isValid = true
  const errors: string[] = []

  if (name.length < 3) {
    isValid = false
    errors.push(translationKey + '.tooShort')
  }

  if (name.length > 50) {
    isValid = false
    errors.push(translationKey + '.tooLong')
  }

  if (/\s{2,}/.test(name)) {
    isValid = false
    errors.push(translationKey + '.toMuchBlankLines')
  }

  return { isValid, errors }
}

export const validateAge = (age: number, translationKey: string): ValidationPayload => {
  let isValid = true
  const errors: string[] = []

  if (age < 0) {
    isValid = false
    errors.push(translationKey + '.negative')
  }

  if (age > 120) {
    isValid = false
    errors.push(translationKey + '.tooOld')
  }

  return { isValid, errors }
}
